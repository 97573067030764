import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
const config = {
    apiKey: "AIzaSyDdaxCtyIowUjViAfBz86fgIo-yxjkbUSw",
    authDomain: "website-max-b00e1.firebaseapp.com",
    databaseURL: "https://website-max-b00e1.firebaseio.com",
    projectId: "website-max-b00e1",
    storageBucket: "",
    messagingSenderId: "63333170341",
    appId: "1:63333170341:web:717c47bcb06905ae"
};
firebase.initializeApp(config);
export default firebase;
